import * as resourcesEn from './en';
import * as resourcesFr from './fr';

const resources = {
    en: {
        ...resourcesEn,
    },
    fr: {
        ...resourcesFr,
    },
};

export default resources;
