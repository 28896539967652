import PhotonickLogo from 'src/assets/images/logos/logo_nico_noir.svg';
import styles from './Home.module.scss';

export const Home = () => {
    return (
        <article className={styles.home}>
            <div>
                <img src={PhotonickLogo} alt='Logo' />
            </div>
        </article>
    );
};
