import { PropsWithChildren } from 'react';
import styles from './ImagedPage.module.scss';

export interface ImagedPageProps {
    src: string;
    reversed?: boolean | undefined;
}

export const ImagedPage = (props: PropsWithChildren<ImagedPageProps>) => {
    const imageContainerInlineStyle = {
        backgroundImage: `url(${props.src})`,
    };

    return (
        <article
            className={`${styles.container} ${props.reversed ? styles.reversedContainers : ''}`}
        >
            <section className={styles.image} style={imageContainerInlineStyle}></section>
            <section className={styles.content}>{props.children}</section>
        </article>
    );
};
