import useImage from 'src/utils/hooks/useImage';
import styles from './ImageCard.module.scss';

interface ImageCardProps {
    src: string;
}

const ImageCard = (props: ImageCardProps) => {
    const { image, loading } = useImage(props.src);

    return !loading ? <img className={styles.image} src={image} alt={props.src} /> : <></>;
};

export default ImageCard;
