import { useTranslation } from 'react-i18next';
import { FaInstagram } from 'react-icons/fa';
import { toggleLanguage } from 'src/i18n';
import styles from './Footer.module.scss';

export const Footer = () => {
    const { t } = useTranslation('common');

    return (
        <footer className={styles.footer}>
            <div>
                <a
                    className={`${styles.instagram} ${styles.button}`}
                    href='https://www.instagram.com/i_fear_heights/'
                >
                    <FaInstagram className={styles.instagramIcon} />
                </a>
            </div>
            <div>
                <a
                    className={`${styles.linkedin} ${styles.button}`}
                    href='https://ca.linkedin.com/in/felixolivierlandry'
                >
                    Félix-Olivier Landry - 2022
                </a>
            </div>
            <div>
                <button
                    className={`${styles.language} ${styles.button}`}
                    onClick={() => toggleLanguage()}
                >
                    {t('footer.language')}
                </button>
            </div>
        </footer>
    );
};
