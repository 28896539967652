import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'src/App.scss';
import { Layout } from 'src/components';
import { Bio, Equipment, Gallery, Home, Services } from 'src/features';

const App = () => (
    <BrowserRouter>
        <Routes>
            <Route path='/' element={<Layout />}>
                <Route index element={<Home />} />
                <Route path='services' element={<Services />} />
                <Route path='gallery' element={<Gallery />} />
                <Route path='bio' element={<Bio />} />
                <Route path='equipments' element={<Equipment />} />
            </Route>
        </Routes>
    </BrowserRouter>
);

export default App;
