import { useTranslation } from 'react-i18next';
import heroImage from 'src/assets/images/services.webp';
import { ImagedPage } from 'src/components';
import styles from './Services.module.scss';

export const Services = () => {
    const { t } = useTranslation('services');

    return (
        <ImagedPage src={heroImage}>
            <div>
                <h1>{t('services.title')}</h1>
                <p>{t('services.text.1')}</p>
                <p>{t('services.text.2')}</p>
                <p>{t('services.text.3')}</p>
            </div>
            <div>
                <h1>{t('contact.title')}</h1>
                <p>{t('contact.text')}</p>
                <p className={styles.contactMe}>
                    <a className={styles.contactInfo} href='mailto:nick_charron@hotmail.com'>
                        {t('contact.writeMe')}
                    </a>

                    <a className={styles.contactInfo} href='tel:4184441524'>
                        +1 (418) 444-1524
                    </a>
                </p>
            </div>
        </ImagedPage>
    );
};
