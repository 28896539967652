import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaBars, FaXmark } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import styles from './Header.module.scss';

interface HeaderRoute {
    to: string;
    translation: string;
}

export const Header = () => {
    const [t] = useTranslation('common');

    const [burgerOpen, set_burgerOpen] = useState(false);
    const [activeRoute, set_activeRoute] = useState(window.location.pathname);

    const routes: HeaderRoute[] = [
        {
            to: '/',
            translation: t('header.nav.home'),
        },
        {
            to: '/services',
            translation: t('header.nav.services'),
        },
        {
            to: '/gallery',
            translation: t('header.nav.gallery'),
        },
        {
            to: '/bio',
            translation: t('header.nav.bio'),
        },
        {
            to: '/equipments',
            translation: t('header.nav.equipments'),
        },
    ];

    const handleLinkClick = (route: string) => {
        set_burgerOpen(false);
        set_activeRoute(route);
        console.log(route);
    };

    return (
        <>
            <header className={styles.header}>
                <div className={styles.burger} onClick={() => set_burgerOpen(!burgerOpen)}>
                    {burgerOpen ? (
                        <FaXmark className={styles.icon} />
                    ) : (
                        <FaBars className={styles.icon} />
                    )}
                </div>
                <nav className={!burgerOpen ? styles.collapse : ''}>
                    {routes.map((route) => (
                        <Link
                            key={route.to}
                            onClick={() => handleLinkClick(route.to)}
                            className={`${styles.link} ${
                                route.to === activeRoute ? styles.active : ''
                            }`}
                            to={route.to}
                        >
                            {route.translation}
                        </Link>
                    ))}
                </nav>
            </header>
        </>
    );
};
