import { useTranslation } from 'react-i18next';
import heroImage from 'src/assets/images/bio.webp';
import { ImagedPage } from 'src/components';

export const Bio = () => {
    const { t } = useTranslation('bio');

    return (
        <ImagedPage src={heroImage}>
            <h1>{t('title')}</h1>
            <p>{t('text.1')}</p>
            <p>{t('text.2')}</p>
            <p>{t('text.3')}</p>
            <p>{t('text.4')}</p>
        </ImagedPage>
    );
};
