import ImageCard from 'src/components/ImageCard/ImageCard';
import styles from './Gallery.module.scss';

export const Gallery = () => {
    const GALLERY_SIZE = 40;
    const imgNames = Array.from(Array(GALLERY_SIZE).keys()).map((x) => x.toString());

    return (
        <article className={styles.gallery}>
            {imgNames.map((imgName) => (
                <ImageCard key={imgName} src={`gallery/${imgName}.jpg`} />
            ))}
        </article>
    );
};
