import { useEffect, useState } from 'react';

interface Image {
    loading: boolean;
    error: any;
    image: any;
}

const useImage = (path: string): Image => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [image, setImage] = useState(null);

    useEffect(() => {
        const fetchImage = async () => {
            try {
                const response = await import(`../../assets/images/${path}`);
                setImage(response.default);
            } catch (err: any) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchImage();
    }, [path]);

    return {
        loading,
        error,
        image,
    };
};

export default useImage;
