import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resources from './locales';

export type LanguageCode = 'en' | 'fr';

const LANGUAGE_FROM_LOCAL_STORAGE_KEY = 'PHOTONICK-LANG';

const DEFAULT_LANGUAGE: LanguageCode = 'fr';

export const getLanguageFromLocalStorage = (): LanguageCode => {
    const languageStateFromLocalStorage = localStorage.getItem(
        LANGUAGE_FROM_LOCAL_STORAGE_KEY,
    ) as LanguageCode;

    return languageStateFromLocalStorage || DEFAULT_LANGUAGE;
};

export const toggleLanguage = () => {
    const language = getLanguageFromLocalStorage();
    language === 'fr' ? setLanguage('en') : setLanguage('fr');
};

const setLanguage = (language: LanguageCode) => {
    localStorage.setItem(LANGUAGE_FROM_LOCAL_STORAGE_KEY, language);
    i18n.changeLanguage(language);
};

declare module 'i18next' {
    interface CustomTypeOptions {
        returnNull: boolean;
    }
}

i18n.use(initReactI18next).init({
    resources,
    returnNull: false,
    lng: getLanguageFromLocalStorage(),
    fallbackLng: 'fr',
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
